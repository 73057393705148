import { axiosInstance } from "../../axios";
const GETSearchAllProducts = `/api/Product/SearchAllProducts`;
const GETSearchProducts = `/api/Product/SearchProducts`;
const GETSearchProductsOnSale = `/api/Product/SearchProductsOnSale`;
const GETValidateProductPurchase = `/api/Product/ValidateProductPurchase`;
const GETSearchProductsById = `/api/Product/GetById/`;
const POSTCreateProduct = `/api/Product/CreateProduct`;
const DELETEProduct = `/api/Product/Delete?id=`;

const SearchAllProducts = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            GETSearchAllProducts
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchProducts = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            GETSearchProducts
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchProductsOnSale = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            GETSearchProductsOnSale
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};


const validateProductPurchase = async function(productId) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(`${GETValidateProductPurchase}/${productId}`);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchProductsById = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            GETSearchProductsById
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SaveProduct = async function(product) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTCreateProduct, product);
        return response.data;
    } catch (error) {
        return null;
    }
};

const RemoveProduct = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.delete(
            DELETEProduct + id
        );
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

export const productData = {
    SearchAllProducts,
    SearchProducts,
    SearchProductsById,
    SaveProduct,
    RemoveProduct,
    validateProductPurchase,
    SearchProductsOnSale
};